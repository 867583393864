import React, {useState, useEffect} from "react"
import "./style.scss"
import Image from "../Image";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/lazy"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import Swiper core and required modules
import SwiperCore, {
    Lazy, Pagination, Navigation, EffectCards
} from 'swiper';

// install Swiper modules
SwiperCore.use([Lazy, Pagination, Navigation, EffectCards]);

const ImageSwiper = props => {
    const { images, setCurrentSlider, initialSlider = 1 } = props
    const [swiperRef, setSwiperRef] = useState(null);

    const slideTo = (index) => {
        swiperRef.slideTo(index - 1, 0);
    };

    useEffect(() => {
        if(swiperRef) {
            slideTo(initialSlider)
        }
    },[swiperRef])

    return (
            <Swiper
                onSwiper={setSwiperRef}
                className="swiper-lazy-style"
                effect={'cards'}
                lazy={true}
                pagination={{
                    "clickable": true
                }}
                navigation={true}
                onSlideChange={(sliderInfo) => { setCurrentSlider(sliderInfo.activeIndex) }}>
                {images && images.map((image, key) => (
                    <SwiperSlide key={key}>
                        <Image src={image} className="w-full border-white border-solid border-4 swiper-lazy" />
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-purple"></div>
                    </SwiperSlide>
                ))}
            </Swiper>
    )
}

export default ImageSwiper
