import React, {useState} from "react"
import Main from "../components/Layout/Main"
import Breadcrumb from "../components/Layout/Breadcrumb"
import {graphql} from 'gatsby'
import Image from "../components/Image";
import Images from "../theme/images";
import SeeAllProducts from "../components/SeeAllProducts";
import "./produto.scss"
import DownloadPdf from "../components/DownloadPdf";
import ImageSwiper from "../components/ImageSwiper";
import Seo from "../components/Seo";
import BoxSwiper from "../components/BoxSwiper";

const Produto = ({ location, data }) => {
    const product = data.allSitePage.edges[0].node.context;
    const [currentProducts, setCurrentProducts] = useState(filterProducts(0))
    const [currentBoxes, setCurrentBoxes] = useState(filterBoxes(0))

    function setCurrentSlider(index) {
        if(product.skuPageChange) {
            setCurrentProducts(filterProducts(index))
            setCurrentBoxes(filterBoxes(index))
        }
    }

    function filterProducts(index) {
        if(product.skuPageChange) {
            return product.skus.filter(function (el) {
                return el.page === index + 1
            })
        }
        return product.skus;
    }

    function filterBoxes(index) {
        if(product.skuPageChange) {
            return product.box.filter(function (el) {
                return el.page === index + 1
            })
        }
        return product.box;
    }

    function renderMeasures(measure) {
        return (
            <div className="grid grid-cols-2 sm:grid-flow-col sm:auto-cols-max sm:grid-cols-none justify-between mt-3 mb-9 text-center">
                {measure.height && (
                    <div>
                        <img loading="lazy" src={Images.productHeight} className="w-16 m-auto" alt="Medida Brinquedo"/>
                        <span className="px-2">{measure.height}</span>
                    </div>
                )}
                {measure.measures && (
                    <div>
                        <img loading="lazy" src={Images.productMeasures} className="w-16 m-auto" alt="Medida Caixa"/>
                        <span className="px-2">{measure.measures}</span>
                    </div>
                )}
                {measure.fitsInBox && (
                    <div>
                        <img loading="lazy" src={Images.productFitsInBox} className="w-16 m-auto" alt="Cabem na Caixa"/>
                        <span className="px-2">{measure.fitsInBox}</span>
                    </div>
                )}
                {measure.weight && (
                    <div>
                        <img loading="lazy" src={Images.productWeight} className="w-16 m-auto" alt="Peso"/>
                        <span className="px-2">{measure.weight}</span>
                    </div>
                )}
            </div>
        )
    }

    return (
        <Main>
            <Seo title={product.name} description={product.description} image={product.feature}/>
            <Breadcrumb product={product}/>
            <div className="container mx-auto px-4">
                {product.logo && (
                    <Image src={product.logo} className="md:hidden m-auto" />
                )}
                {product.description && (
                    <p className="text-darkblue font-bold text-center md:text-left md:pt-20 md:pb-8 lg:w-3/12">
                        {product.description}
                    </p>
                )}
                <div className="xl:grid xl:grid-cols-2 gap-4">
                    <div className={`p-12 ${product.lamina.length > 1 ?"h-96 lg:h-auto product-height":""}`}>
                        {product.lamina.length === 1 && (
                            <Image src={product.lamina[0]} className="w-full border-white border-solid border-4 shadow-xl" />
                        )}
                        {product.lamina.length > 1  && (
                            <ImageSwiper images={product.lamina} setCurrentSlider={setCurrentSlider} initialSlider={location.state?.page ?? null} />
                        )}
                    </div>
                    <div className="mt-12 lg:pt-0 lg:px-12 2xl:p-44">
                        <div className="mx-5 sm:mx-0 border-black border-2 bg-white relative">
                                {/*currentBoxes && currentBoxes.map((box, key) => (
                                    <Image src={box.image} key={key} />
                                ))*/}
                            <div className="w-full h-64 -mt-32 overflow-hidden">
                                <BoxSwiper boxes={currentBoxes}/>
                            </div>
                            <div className="p-10 -mt-24">
                                <ul className={product?.box[location?.state?.boxKey??0]?.image?"pt-20":""}>
                                    {
                                        product.skuPageChange && (
                                            <p className="text-purple text-xl font-bold">Produtos da página atual:</p>
                                        )
                                    }
                                    {currentProducts && currentProducts.map((product, key) => (
                                        <li key={key}>
                                            <p className="font-bold pt-5">{product.sku}</p>
                                            <span className="text-darkblue pt-5">{product.name}</span>
                                            {product.info && renderMeasures(product.info)}
                                        </li>
                                    ))}
                                </ul>
                                {product.info && renderMeasures(product.info)}
                            </div>
                        </div>
                        {product.downloadUrl  && (
                            <div className="mt-9 px-5 sm:px-32 md:px-0">
                                <DownloadPdf sendCatalog={false} downloadUrl={product.downloadUrl} name={product.name}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/*<div className="border-b-4 border-purple">
                <div className="text-center bg-purple text-white font-bold text-2xl py-2">Produtos Relacionados</div>
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-3 gap-4 text-center my-16">
                        {product.related.map((related, key) => (
                            <Link to={related.slug} key={key}>
                                <Image src={related.box} className="m-auto"/>
                                <span className="text-purple font-bold">{related.name}</span>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>*/}
            <div className="border-t-4 border-darkpurple bg-purple-custom">
                <div className="container mx-auto px-4">
                    <div className="flex flex-col-reverse md:flex-row">
                        <div className="flex-1">
                            <img loading="lazy" src={Images.folder} className="md:float-right" alt="" />
                        </div>
                        <div className="flex-1 pt-12 px-12 md:pt-10 lg:px-32">
                            <DownloadPdf sendCatalog/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-6 bg-darkpurple w-full"></div>
            <SeeAllProducts/>
        </Main>
    )
}

export default Produto;

export const query = graphql`
    query($path: String!) {
        allSitePage(filter: { path: { eq: $path } }) {
            edges {
                node {
                    context {
                        name
                        isNew
                        description
                        logo
                        lamina
                        box {
                            page
                            image
                        }
                        feature
                        skuPageChange
                        downloadUrl
                        skus {
                            name
                            sku
                            info {
                                height
                                measures
                                fitsInBox
                                weight
                            }
                            page
                        }
                        info {
                            height
                            measures
                            fitsInBox
                            weight
                        }
                        related {
                            name
                            slug
                            box
                        }
                    }
                }
            }
        }
    }
`