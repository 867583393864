import React, {useState, useEffect} from "react"
import "./style.scss"
import Image from "../Image";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/lazy"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import Swiper core and required modules
import SwiperCore, {
    Lazy, Pagination, Navigation, EffectCards, Autoplay
} from 'swiper';

// install Swiper modules
SwiperCore.use([Lazy, Pagination, Navigation, EffectCards, Autoplay]);

const BoxSwiper = props => {
    const { boxes } = props

    return (
        <Swiper
            className="swiper-lazy-style"
            slidesPerView={"auto"}
            autoplay={{
                "delay": 4500,
                "disableOnInteraction": false
            }}
            lazy={true}
            pagination={{
                "clickable": true
            }}>
            {boxes && boxes.map((box, key) => (
                <SwiperSlide key={key}>
                    <Image src={box.image} className="w-96 h-32" />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default BoxSwiper
